<template>
  <div :class="className" :style="imgStyle" />
</template>

<script>
export default {
  name: 'SuspenseImg',

  props: {
    height: {
      type: String,
      default: '10rem',
      validator: (x) => x.endsWith('rem') || x.endsWith('px'),
    },
  },

  data() {
    return {
      className: 'the-suspense-img',
    }
  },

  computed: {
    imgStyle() {
      return `height: ${this.height};`
    },
  },
}
</script>
